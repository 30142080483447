.custom-tooltip {
  border-radius: 15px;
  background-color: white;
  padding: 0.5rem;
}

.CardDeckDashoard {
  justify-content: space-evenly;
}

.CardDashboard {
  width: 65rem;
}

.CardDashboardbody {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.pieChart {
  max-width: 450px;
}