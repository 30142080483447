div.card-style.device.card {
  margin: 0;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  padding: 0;
}

div.card-header {
  background: #dadada;
}

.device-info-title {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

div.modal-header {
  background: #e9ecef;
}

label.form-label {
  font-weight: 500;
}
i {
  font-weight: 400;
}
